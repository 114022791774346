import React, { useEffect, useState } from "react";
import "../../App.css";
import MainLayout from "../../Layouts/MainLayout";
import {
  Table,
  Icon,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  TextArea,
  ModalFooter,
  Select,
} from "design-react-kit";

import { useParams, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import listAPI from "../../Apis/EnumAPI";
import { GenericAPI } from "../../Apis/GenericAPI";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function IndexServiceRequest(props) {
  let params = useParams();

  const navigate = useNavigate();
  const [requests, setRequests] = useState(null);
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [value, setValue] = useState(null);
  const [valueMethod, setValueMethod] = useState(null);

  //calendario chiusure
  const [isDateOpened, setIsDateOpened] = useState(false);
  const [isDataSelected, setIsDataSelected] = useState("");
  const [datesList, setDatesList] = useState([]);

  // Stati per i valori precompilati
  const [serviceDate, setServiceDate] = useState("");
  const [serviceNumber, setServiceNumber] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [config, setConfig] = useState(null);

  useEffect(() => {
    console.log("Esecuzione di useEffect per recuperare le date");
    fetchAllDates();
    callApiWithService({ service_id: params.service_id });
  }, []);

  const fetchAllDates = async () => {
    try {
      const response = await GenericAPI.get_dates();
      console.log("Risposta ottenuta:", response);
      if (response && response.data) {
        setDatesList(response.data.map((date) => ({ data: date }))); // Trasforma ogni data in un oggetto con una proprietà 'data'
      }
    } catch (error) {
      console.error("Errore durante il recupero delle date:", error);
    }
  };

  const addDate = (e) => {
    setIsDataSelected(e.target.value);
    console.log(e.target.value);
  };

  /* const callApiWithDate = async (date) => {
    try {
      const response = await GenericAPI.add_newDate(date);
      console.log(response);
      if (response.success) {
        // Gestisci la risposta qui, ad esempio aggiornando lo stato
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
  }; */

  const callApiWithDate = async (date) => {
    try {
      const response = await GenericAPI.add_newDate({
        data: date,
      });
      console.log(response);
      if (response.success) {
        fetchAllDates();
        window.location.reload();
        //setDatesList((prevDates) => [...prevDates, date]); // Aggiungi la data inviata alla lista
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
  };

  const callApiWithTime = async (json) => {
    setIsLoading(true);
    try {
      const response = await GenericAPI.add_serviceTime(json);
      console.log(response);
      if (response.success) {
        //fetchAllDates();
        //window.location.reload();
        //setDatesList((prevDates) => [...prevDates, date]); // Aggiungi la data inviata alla lista
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
    } finally {
      setIsLoading(false);
      //window.location.reload();
    }
  };

  function isValidCodiceFiscale(cf) {
    const cfRegex =
      /^[A-Z]{6}[0-9]{2}[ABCDEHLMPRST]{1}[0-9]{2}([A-Z]{1}[0-9]{3})[A-Z]{1}$/i;
    return cfRegex.test(cf);
  }

  const callApiWithMultaData = async (data) => {
    if (!isValidCodiceFiscale(data.codiceFiscale)) {
      alert("Il codice fiscale inserito non è valido.");
      return;
    }
    setIsLoading(true);

    try {
      const response = await GenericAPI.add_newMulta(data);

      if (response) {
        // Svuota il form
        document.getElementById("nome").value = "";
        document.getElementById("cognome").value = "";
        document.getElementById("codice-fiscale").value = "";
        document.getElementById("targa").value = "";
        document.getElementById("email").value = "";
        document.getElementById("scadenza-pagamento").value = "";
        document.getElementById("causale").value = "";
        document.getElementById("importo").value = "";

        // Mostra un messaggio di successo
        alert("La multa è stata generata con successo.");
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
      // Se necessario, gestisci gli errori qui
      // Esempio: mostra un messaggio di errore all'utente
    } finally {
      setIsLoading(false);
    }
  };

  const callApiWithService = async (json) => {
    try {
      const response = await GenericAPI.get_serviceTime(json);

      if (response.data[0]) {
        const data = response.data[0];
        //console.log(data);
        if (data) {
          setServiceDate(data.date || "");
          setServiceNumber(data.days || "");
          setServiceDescription(data.description || "");
        }
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
  };

  const handleDeleteDate = async (id) => {
    try {
      const response = await GenericAPI.deleteDate(id);
      if (response.success) {
        /* setDatesList((prevDates) =>
          prevDates.filter((date) => date.data.id !== id)
        ); */
        window.location.reload();
        console.log("Data eliminata con successo");
      } else {
        console.error("Errore nell'eliminazione della data");
      }
    } catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
  };

  const [saved, setSaved] = useState({
    id: "",
    status: "",
    message: "",
    service_id: "",
  });
  const [savedProtocol, setSavedProtocol] = useState({
    id: "",
    register: "",
    service_id: "",
    date: "",
  });
  const [savedPayment, setSavedPayment] = useState({
    id: "",
    amount: "",
    cause: "",
    practice_number: "",
  });
  const [search, setSearch] = useState("");
  const [username, setUsername] = useState("");
  const [id, setId] = useState("");
  const [practice_n, setPractice_n] = useState("");
  const [protocolo_n, setProtocol_n] = useState("");
  const [ufficio, setUfficio] = useState("");
  const [data, setData] = useState("");
  const [dataDisservizio, setDataDisservizio] = useState("");
  const [link_s3, set_linkS3] = useState("");
  const [searchData, setSearchData] = useState({});
  //calendario chiusure
  const openDatePopup = (e) => {
    setIsDateOpened(!isDateOpened);
  };

  const closeDatePopup = () => {
    setIsDateOpened(false);
  };
  //Info
  const [openInfo, toggleModalInfo] = useState(false);
  const toggleInfo = () => toggleModalInfo(!openInfo);
  const openModalInfo = () => {
    toggleModalInfo(true);
  };
  //Info Payment
  const [openInfoPayAmount, toggleModalInfoPayAmount] = useState(false);
  const toggleInfoPayAmount = () =>
    toggleModalInfoPayAmount(!openInfoPayAmount);
  const openModalInfoPayAmount = () => {
    toggleModalInfoPayAmount(true);
  };
  //Info Protocol
  const [openInfoProtocol, toggleModalInfoProtocol] = useState(false);
  const toggleInfoProtocol = () => toggleModalInfoProtocol(!openInfoProtocol);
  const openModalInfoProtocol = () => {
    toggleModalInfoProtocol(true);
  };
  //Info
  const [openInfoPayment, toggleModalInfoPayment] = useState(false);
  const toggleInfoPayment = () => toggleModalInfoPayment(!openInfoPayment);
  const openModalInfoPayment = () => {
    toggleModalInfoPayment(true);
  };
  //Modifica mensa e trasporto
  const [open, toggleModal] = useState(false);
  const toggle = () => toggleModal(!open);
  const openModal = (id, p_number) => {
    setId(id);
    setPractice_n(p_number);
    toggleModal(true);
  };
  //protocollo
  const [openProtocol, toggleModalProtocol] = useState(false);
  const toggleProtocol = () => toggleModalProtocol(!openProtocol);
  const openModalProtocol = (id, p_number, register) => {
    setId(id);
    setPractice_n(p_number);
    setProtocol_n(register);
    toggleModalProtocol(true);
  };
  //pagamento
  const [openPayment, toggleModalPayment] = useState(false);
  const togglePayment = () => toggleModalPayment(!openPayment);
  const openModalPayment = (id, p_number, register) => {
    setId(id);
    setPractice_n(p_number);
    setProtocol_n(register);
    toggleModalPayment(true);
  };
  const [open2, toggleModal2] = useState(false);
  const toggle2 = () => toggleModal2(!open2);
  const openModal2 = (name) => {
    setUsername(name);
    toggleModal2(true);
  };
  //Info Prenotazione appuntamento
  const [open3, toggleModal3] = useState(false);
  const toggle3 = () => toggleModal3(!open3);
  const openModal3 = (id, p_number, ufficio) => {
    setId(id);
    setPractice_n(p_number);
    setUfficio(ufficio);
    //setSearch()
    toggleModal3(true);
  };
  //Info Richiesta assistenza
  const [open4, toggleModal4] = useState(false);
  const toggle4 = () => toggleModal4(!open4);
  const openModal4 = (id, p_number, data) => {
    setId(id);
    setPractice_n(p_number);
    setData(data);
    //setSearch()
    toggleModal4(true);
  };
  //Info Segnalazione disservizio
  const [showImage, setShowImage] = useState(false);
  const toggleImage = () => {
    setShowImage(!showImage);
  };
  const [open5, toggleModal5] = useState(false);
  const toggle5 = () => toggleModal5(!open5);
  const openModal5 = (id, p_number, data) => {
    setId(id);
    setPractice_n(p_number);
    setDataDisservizio(data);
    getS3Link(id, p_number, data);
    //setSearch()
    toggleModal5(true);
  };

  const defaultOptions = [
    { value: "in_progress", label: "in corso" },
    { value: "to_complete", label: "da completare" },
    { value: "draft", label: "in bozza" },
    { value: "waiting", label: "in attesa" },
    { value: "error", label: "rifiutata" },
    { value: "completed", label: "conclusa" },
  ];

  const defaultPaymentMethod = [
    { value: true, label: "Pagamento multiplo" },
    { value: false, label: "Pagamento singolo" },
  ];

  const defaultServices = [
    { value: "SPA", label: "Prenotazione appuntamento" },
    { value: "SRA", label: "Richiesta di assistenza" },
    { value: "SSD", label: "Segnalazione disservizio" },
    { value: "RMS", label: "Richiesta mensa scolastica" },
    { value: "PTI", label: "Pagamento IMU - F24" },
    { value: "RTS", label: "Richiesta trasporto scolastico" },
    { value: "PSP", label: "Occupazione suolo pubblico" },
    { value: "PCO", label: "Pagamento contravvenzioni" },
    { value: "RAAT", label: "Richiesta accesso agli atti" },
    { value: "PPI", label: "Richiedere permesso di parcheggio invalidi" },
    { value: "DBE", label: "Presentazione domanda per bonus economici" },
  ];
  useEffect(() => {
    //inizioalize first view first step
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);

      console.log(config.data.current.id);
      setConfig(config);
      setHeader(config.data.current);
    }
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      console.log(user);
    }
    getRequest();
  }, []);

  const handleChange = (e) => {
    setValue(e.target.options[e.target.selectedIndex].value);
  };

  const handleChangeMethod = (e) => {
    setValueMethod(e.target.options[e.target.selectedIndex].value);
  };

  const getRequest = async () => {
    try {
      const response = await GenericAPI.get(
        listAPI.ListServcieRequest,
        params.service_id
      );
      setRequests(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  //ho creato questa funzione per recuperarmi la label in italiano, modifica pure se non va bene, è funzionante
  function findLabelByValue(value) {
    const option = defaultServices.find((option) => option.value === value);
    return option ? option.label : "";
  }

  function findStatusByValue(value) {
    const option = defaultOptions.find((option) => option.value === value);
    return option ? option.label : "";
  }

  const save = async () => {
    try {
      //const selectedLabel = findLabelByValue(value);

      //console.log(selectedLabel);
      await GenericAPI.save({
        status: value,
        id: id,
        message: saved.message,
        service_id: params.service_id,
      });
      toggle();
      getRequest();
      //window.location.reload(true)
    } catch (err) {
      console.log(err);
    }
  };

  const saveProtocol = async () => {
    try {
      //const selectedLabel = findLabelByValue(value);

      //console.log(selectedLabel);
      await GenericAPI.save({
        id: id,
        service_id: params.service_id,
        register: savedProtocol.register,
        date: savedProtocol.date,
      });
      toggleProtocol();
      getRequest();
      //window.location.reload(true)
    } catch (err) {
      console.log(err);
    }
  };
  const savePayment = async () => {
    try {
      //const selectedLabel = findLabelByValue(value);

      //console.log(selectedLabel);
      await GenericAPI.add_newPayment({
        id: id,
        cause: savedPayment.cause,
        amount: savedPayment.amount,
        practice_number: practice_n,
        payment_method_type: valueMethod,
      });
      togglePayment();
      getRequest();
      //window.location.reload(true)
    } catch (err) {
      console.log(err);
    }
  };

  const getS3Link = async (id, p_number, d) => {
    try {
      const response = await GenericAPI.get_s3_link({
        practice_number: p_number,
        practice_id: id,
        data: d.disservizio,
      });
      console.log(response?.data);

      set_linkS3(response?.data);
      //togglePayment()
      //window.open(response, "__blank")
      //window.location.reload(true)
    } catch (err) {
      console.log(err);
    }
  };

  const messageSaving = (e) => {
    //console.log(e.target.value);
    setSaved((prev) => {
      return {
        ...prev,
        message: e.target.value,
        id: e.target.getAttribute("data-protocollo"),
      };
    });
  };
  const protocolSaving = (e) => {
    //console.log(e.target.type)
    if (e.target.type === "text") {
      setSavedProtocol((prev) => {
        return {
          ...prev,
          register: e.target.value,
        };
      });
    } else {
      setSavedProtocol((prev) => {
        return {
          ...prev,
          date: e.target.value,
          id: e.target.getAttribute("data-protocollo"),
        };
      });
    }
  };
  const paymentSaving = (e) => {
    //console.log(e.target.id)
    if (e.target.id === "areaPayment") {
      setSavedPayment((prev) => {
        return {
          ...prev,
          amount: e.target.value,
        };
      });
    } else {
      setSavedPayment((prev) => {
        return {
          ...prev,
          cause: e.target.value,
          id: e.target.getAttribute("data-protocollo"),
        };
      });
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSearch = async (e) => {
    try {
      const res = await GenericAPI.search(params.service_id, search);
      //console.log(res);
      console.log(res.data);
      setRequests(res.data);
      setSearchData(res.data);
      //console.log(data);
      //usa setSearchData() per salvarci quello che ti ritorna l'api search
      //dsadsa
    } catch (err) {
      console.log(err);
    }
  };
  const onDownload = async (id, practice_number) => {
    try {
      GenericAPI.download(listAPI.DownloadAttach, id).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", practice_number + "_allegati.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onImageDownload = async (id, practice_number) => {
    try {
      GenericAPI.download(listAPI.DownloadAttach, id).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", practice_number + "_immagini.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      console.log(err);
    }
  };

  //const desiredService = {header.services?.map(service => (
  //  <h2>{service.title}</h2>
  //))}
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = ["Id,N° pratica,Data,N° protocollo,Stato,Contatto,Servizio"];
    //let practice_number = requests?.practice_number
    // Convert users data to a csv
    const usersCsv = requests?.reduce((acc, user) => {
      const {
        id,
        practice_number,
        send_date,
        status,
        contact_email,
        service,
      } = //aggiungere numero protocllo
        user;
      acc.push(
        [
          id,
          practice_number,
          send_date,
          findStatusByValue(status),
          contact_email,
          service?.service_type,
        ].join(",")
      );
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "Pratiche-" + findLabelByValue(params.service_id) + ".csv",
      fileType: "text/csv",
    });
  };

  const history = useNavigate();

  const indietro = () => {
    navigate(-1);
  };

  return (
    <MainLayout auth={user} errors={props.errors} header={header}>
      <Container className="my-5">
        <Button
          style={{
            marginBottom: "50px",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
          onClick={indietro}
          tabIndex="-1"
          color="primary"
          size="xs"
          icon
        >
          <Icon icon="it-arrow-left" color="white" />
          Torna alla lista dei servizi
        </Button>
        {params.service_id !== "SPA" &&
        params.service_id !== "SSD" &&
        params.service_id !== "PTI" &&
        params.service_id !== "SRA" &&
        params.service_id !== "PCO" ? (
          <>
            <h3 className="fw-normal mb-5">
              Tempi di elaborazione delle richieste:
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                marginBottom: "20px",
                gap: "10px",
              }}
            >
              <div style={{ flex: "0 0 48%" }}>
                <label htmlFor="service-date">
                  Termine presentazione delle richieste
                </label>
                <input
                  type="date"
                  id="service-date"
                  defaultValue={serviceDate}
                  onChange={addDate}
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div style={{ flex: "0 0 48%" }}>
                <label htmlFor="service-number">
                  Giorni previsti per l'elaborazione delle richieste
                </label>
                <input
                  type="number"
                  id="service-number"
                  defaultValue={serviceNumber}
                  placeholder="Inserisci numero giorni di erogazione"
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <Button
                onClick={() => {
                  const serviceDate =
                    document.getElementById("service-date").value;
                  const serviceNumber =
                    document.getElementById("service-number").value;
                  const serviceDescription = document.getElementById(
                    "service-description"
                  ).value;
                  callApiWithTime({
                    service_id: params.service_id,
                    service_date: serviceDate,
                    service_number: serviceNumber,
                    service_description: serviceDescription,
                  });
                }}
                tabIndex="-1"
                color="primary"
                size="xs"
                icon
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  height: "45px",
                }}
                disabled={isLoading}
              >
                {isLoading ? <Spinner active label /> : "Salva"}
              </Button>
            </div>

            <div style={{ marginBottom: "20px", display: "none" }}>
              <label htmlFor="service-description">Descrizione</label>
              <input
                type="text"
                id="service-description"
                defaultValue={serviceDescription}
                placeholder="Inserisci descrizione"
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            <br />
            <br />
          </>
        ) : (
          <br />
        )}
        {params.service_id === "SPA" ? (
          <>
            <h3 className="fw-normal mb-5">Date di chiusura comunali:</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <input
                type="date"
                id="appontiment-date"
                onChange={addDate}
                style={{
                  width: "80%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              ></input>
              <Button
                onClick={() => callApiWithDate(isDataSelected)}
                tabIndex="-1"
                color="primary"
                size="xs"
                icon
                style={{
                  width: "18%", // Aumentato per mantenere le proporzioni
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
              >
                Aggiungi data
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: "10px",
              }}
            >
              {datesList.map((date, index) => (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {date.data.data === "null"
                      ? "Nessuna data di chiusura ancora inserita"
                      : (() => {
                          const dateObj = new Date(date.data.data);
                          const day = dateObj.getDate();
                          const monthNames = [
                            "gennaio",
                            "febbraio",
                            "marzo",
                            "aprile",
                            "maggio",
                            "giugno",
                            "luglio",
                            "agosto",
                            "settembre",
                            "ottobre",
                            "novembre",
                            "dicembre",
                          ];
                          const month = monthNames[dateObj.getMonth()];
                          const year = dateObj.getFullYear();
                          return `${day} ${month} ${year}`;
                        })()}
                  </span>
                  {date.data.id && (
                    <button
                      onClick={() => handleDeleteDate(date.data.id)}
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Icon icon="it-close" color="white" />
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div>
              <br />
              <br />
            </div>
          </>
        ) : (
          <br />
        )}

        <h2 className="fw-normal mb-5">
          Pratiche - {findLabelByValue(params.service_id)}
        </h2>

        {params.service_id == "PCO" ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                gap: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }}>
                  <label htmlFor="nome">Nome intestatario:</label>
                  <input
                    type="text"
                    id="nome"
                    placeholder="Inserisci nome"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <label htmlFor="cognome">Cognome intestatario:</label>
                  <input
                    type="text"
                    id="cognome"
                    placeholder="Inserisci cognome"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }}>
                  <label htmlFor="codice-fiscale">
                    Codice Fiscale intestatario:
                  </label>
                  <input
                    type="text"
                    id="codice-fiscale"
                    placeholder="Inserisci codice fiscale"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <label htmlFor="email">Email intestatario:</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Inserisci email"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }}>
                  <label htmlFor="targa">Targa veicolo:</label>
                  <input
                    type="text"
                    id="targa"
                    placeholder="Inserisci targa"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <label htmlFor="scadenza-pagamento">Data scadenza:</label>
                  <input
                    type="date"
                    id="scadenza-pagamento"
                    placeholder="Scadenza pagamento"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "48%" }}>
                  <label htmlFor="causale">Descrizione pagamento:</label>
                  <input
                    type="text"
                    id="causale"
                    placeholder="Inserisci causale"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <label htmlFor="importo">Importo dovuto:</label>
                  <input
                    type="number"
                    id="importo"
                    placeholder="Inserisci importo"
                    style={{
                      width: "100%",
                      padding: "10px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                </div>
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    const nome = document.getElementById("nome").value;
                    const cognome = document.getElementById("cognome").value;
                    const codiceFiscale =
                      document.getElementById("codice-fiscale").value;
                    const targa = document.getElementById("targa").value;
                    const email = document.getElementById("email").value;
                    const scadenzaPagamento =
                      document.getElementById("scadenza-pagamento").value;
                    const causale = document.getElementById("causale").value;
                    const importo = document.getElementById("importo").value;

                    // Verifica che tutti i campi siano compilati
                    if (
                      !nome ||
                      !cognome ||
                      !codiceFiscale ||
                      !targa ||
                      !email ||
                      !scadenzaPagamento ||
                      !causale ||
                      !importo
                    ) {
                      alert("Per favore, compila tutti i campi.");
                      return;
                    }

                    callApiWithMultaData({
                      nome: nome,
                      cognome: cognome,
                      codiceFiscale: codiceFiscale,
                      targa: targa,
                      email: email,
                      scadenzaPagamento: scadenzaPagamento,
                      causale: causale,
                      importo: importo,
                    });
                  }}
                  tabIndex="-1"
                  color="primary"
                  size="xs"
                  icon
                  style={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    width: "20%",
                    height: "45px",
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner active label /> : "Salva"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <br />
        )}

        {params.service_id != "PCO" ? (
          <>
            <div
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
              }}
            >
              <input
                type="text"
                id="searchBar"
                className="search"
                placeholder={"Ricerca per numero di pratica"}
                onChange={onChangeSearch}
              />
              <input
                className="search-btn"
                type="button"
                value="&#9740;"
                onClick={onSearch}
              />
              <Button
                style={{
                  textAlign: "center",
                  whiteSpace: "nowrap",
                }}
                onClick={exportToCsv}
                tabIndex="-1"
                color="primary"
                size="xs"
                icon
              >
                <Icon icon="it-download" color="white" />
                Scarica CSV
              </Button>
            </div>

            <main>
              {requests == null ? (
                <Spinner active label />
              ) : (
                <div
                  style={{ maxHeight: "800px", overflow: "auto" }}
                  className="py-12"
                >
                  <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                      <div className="p-6 bg-white border-b border-gray-200">
                        <div className="flex items-center justify-between mb-6"></div>

                        <Table striped>
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">N° pratica</th>
                              <th scope="col">Data richiesta</th>
                              {(params.service_id === "RMS") |
                              (params.service_id === "RTS") |
                              (params.service_id === "PCO") |
                              (params.service_id === "PSP") |
                              (params.service_id === "RAAT") ? (
                                //posso usare anche <React.Fragment>... </React.Fragment>
                                <>
                                  <th scope="col">N° protocollo</th>
                                  <th scope="col">Stato</th>
                                  <th scope="col">Modifica</th>
                                  <th scope="col">Documenti</th>
                                  {params.service_id === "RTS" &&
                                  config?.data?.current?.id === "E070" ? (
                                    ""
                                  ) : (
                                    <th scope="col">Pagamenti</th>
                                  )}
                                </>
                              ) : (params.service_id === "PPI") |
                                (params.service_id === "DBE") ? (
                                //posso usare anche <React.Fragment>... </React.Fragment>
                                <>
                                  <th scope="col">N° protocollo</th>
                                  <th scope="col">Stato</th>
                                  <th scope="col">Modifica</th>
                                  <th scope="col">Documenti</th>
                                </>
                              ) : params.service_id === "PTI" ? (
                                <th scope="col">Pagamenti</th>
                              ) : params.service_id === "SSD" ? (
                                <>
                                  <th scope="col">N° protocollo</th>
                                  <th scope="col">Dettagli</th>
                                </>
                              ) : (
                                <th scope="col">Dettagli</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {requests?.map(
                              ({
                                id,
                                register,
                                status,
                                data,
                                created_at,
                                practice_number,
                                amount,
                              }) => (
                                <tr
                                  key={Math.random() * 31 * Math.random() * 11}
                                >
                                  <th scope="row">{id}</th>
                                  <td>{practice_number}</td>

                                  <td>
                                    {dayjs(created_at)
                                      .utc()
                                      .format("DD/MM/YYYY HH:mm:ss")}
                                  </td>

                                  {(params.service_id === "RMS") |
                                  (params.service_id === "RTS") |
                                  (params.service_id === "PCO") |
                                  (params.service_id === "RAAT") ? (
                                    <>
                                      <td>
                                        {register == null ? (
                                          <Button
                                            onClick={() =>
                                              openModalProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          register
                                        )}
                                      </td>
                                      <td>{findStatusByValue(status)}</td>

                                      <td>
                                        {status != "draft" &&
                                        register != null ? (
                                          <Button
                                            onClick={() =>
                                              openModal(id, practice_number)
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => openModalInfo()}
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        {status != "draft" ? (
                                          <Button
                                            onClick={() =>
                                              onDownload(id, practice_number)
                                            }
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-clip"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      {params.service_id === "RTS" &&
                                      config?.data?.current?.id === "E070" ? (
                                        ""
                                      ) : (
                                        <td>
                                          {status == "completed" ? (
                                            <Button
                                              onClick={() =>
                                                openModalPayment(
                                                  id,
                                                  practice_number,
                                                  register
                                                )
                                              }
                                              tabIndex="-1"
                                              color="primary"
                                              size="xs"
                                              icon
                                            >
                                              <Icon
                                                icon="it-plus"
                                                color="white"
                                              />
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                openModalInfoPayment()
                                              }
                                              id={id}
                                              tabIndex="-1"
                                              color="primary"
                                              size="xs"
                                              icon
                                            >
                                              <Icon
                                                icon="it-help"
                                                color="white"
                                              />
                                            </Button>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  ) : params.service_id === "PSP" ? (
                                    <>
                                      <td>
                                        {register == null &&
                                        status != "to_complete" ? (
                                          <Button
                                            onClick={() =>
                                              openModalProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : status == "to_complete" ? (
                                          <Button
                                            onClick={() =>
                                              openModalInfoProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          register
                                        )}
                                      </td>
                                      <td>{findStatusByValue(status)}</td>

                                      <td>
                                        {status != "draft" &&
                                        register != null ? (
                                          <Button
                                            onClick={() =>
                                              openModal(id, practice_number)
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => openModalInfo()}
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        {status != "draft" ? (
                                          <Button
                                            onClick={() =>
                                              onDownload(id, practice_number)
                                            }
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-clip"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>
                                        {status == "completed" ? (
                                          <Button
                                            onClick={() =>
                                              openModalPayment(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-plus"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              openModalInfoPayment()
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        )}
                                      </td>
                                    </>
                                  ) : (params.service_id === "PPI") |
                                    (params.service_id === "DBE") ? (
                                    <>
                                      <td>
                                        {register == null &&
                                        status != "to_complete" ? (
                                          <Button
                                            onClick={() =>
                                              openModalProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : status == "to_complete" ? (
                                          <Button
                                            onClick={() =>
                                              openModalInfoProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          register
                                        )}
                                      </td>
                                      <td>{findStatusByValue(status)}</td>

                                      <td>
                                        {status != "draft" &&
                                        register != null ? (
                                          <Button
                                            onClick={() =>
                                              openModal(id, practice_number)
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => openModalInfo()}
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-help"
                                              color="white"
                                            />
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        {status != "draft" ? (
                                          <Button
                                            onClick={() =>
                                              onDownload(id, practice_number)
                                            }
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-clip"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </>
                                  ) : params.service_id === "SPA" ? (
                                    <td>
                                      <Button
                                        onClick={() =>
                                          openModal3(
                                            id,
                                            practice_number,
                                            data.ufficio
                                          )
                                        }
                                        id={id}
                                        tabIndex="-1"
                                        color="primary"
                                        size="xs"
                                        icon
                                      >
                                        <Icon
                                          icon="it-info-circle"
                                          color="white"
                                        />
                                      </Button>
                                    </td>
                                  ) : params.service_id === "SRA" ? (
                                    <td>
                                      <Button
                                        onClick={() =>
                                          openModal4(id, practice_number, data)
                                        }
                                        id={id}
                                        tabIndex="-1"
                                        color="primary"
                                        size="xs"
                                        icon
                                      >
                                        <Icon
                                          icon="it-info-circle"
                                          color="white"
                                        />
                                      </Button>
                                    </td>
                                  ) : params.service_id === "SSD" ? (
                                    <>
                                      <td>
                                        {register == null ? (
                                          <Button
                                            onClick={() =>
                                              openModalProtocol(
                                                id,
                                                practice_number,
                                                register
                                              )
                                            }
                                            id={id}
                                            tabIndex="-1"
                                            color="primary"
                                            size="xs"
                                            icon
                                          >
                                            <Icon
                                              icon="it-pencil"
                                              color="white"
                                            />
                                          </Button>
                                        ) : (
                                          register
                                        )}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() =>
                                            openModal5(
                                              id,
                                              practice_number,
                                              data
                                            )
                                          }
                                          id={id}
                                          tabIndex="-1"
                                          color="primary"
                                          size="xs"
                                          icon
                                        >
                                          <Icon
                                            icon="it-info-circle"
                                            color="white"
                                          />
                                        </Button>
                                      </td>
                                    </>
                                  ) : params.service_id === "PTI" ? (
                                    amount != 0 ? (
                                      <td>
                                        <Button
                                          onClick={() =>
                                            openModalPayment(
                                              id,
                                              practice_number,
                                              register
                                            )
                                          }
                                          tabIndex="-1"
                                          color="primary"
                                          size="xs"
                                          icon
                                        >
                                          <Icon icon="it-plus" color="white" />
                                        </Button>
                                      </td>
                                    ) : (
                                      <td>
                                        <Button
                                          onClick={() =>
                                            openModalInfoPayAmount()
                                          }
                                          tabIndex="-1"
                                          color="primary"
                                          size="xs"
                                          icon
                                        >
                                          <Icon
                                            icon="it-info-circle"
                                            color="white"
                                          />
                                        </Button>
                                      </td>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              )
                            )}
                            {requests?.length === 0 && (
                              <tr>
                                <td colSpan="6">No request found.</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </main>
          </>
        ) : (
          <br />
        )}
      </Container>
      {/* mensa e trasporto */}

      <Modal fade centered isOpen={open} toggle={toggle} labelledBy="esempio14">
        <ModalHeader toggle={toggle} id="esempio14">
          MODIFICA , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <select
                style={{
                  width: "100%",
                  border: "none",
                  borderBottom: "solid 1px darkgrey",
                }}
                id="status"
                onChange={handleChange}
              >
                <option selected disabled>
                  Scegli il nuovo stato
                </option>
                <option value={defaultOptions[0].value}>
                  {defaultOptions[0].label}
                </option>
                <option value={defaultOptions[1].value}>
                  {defaultOptions[1].label}
                </option>
                <option value={defaultOptions[2].value}>
                  {defaultOptions[2].label}
                </option>
                <option value={defaultOptions[3].value}>
                  {defaultOptions[3].label}
                </option>
                <option value={defaultOptions[4].value}>
                  {defaultOptions[4].label}
                </option>
                <option value={defaultOptions[5].value}>
                  {defaultOptions[5].label}
                </option>
              </select>
            </FormGroup>
            <FormGroup>
              <label htmlFor="areaMessage"></label>
              <textarea
                id="areaMessage"
                data-protocollo={id}
                onBlur={messageSaving}
                placeholder="Aggiungi un messaggio"
              ></textarea>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Chiudi
          </Button>
          <Button color="primary" onClick={save}>
            Salva modifiche
          </Button>
        </ModalFooter>
      </Modal>
      {/* protocollo */}

      <Modal
        fade
        centered
        isOpen={openProtocol}
        toggle={toggleProtocol}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={toggleProtocol} id="esempio14">
          PROTOCOLLO , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <label htmlFor="areaProtocol"></label>
              <input
                className="form-control"
                id="areaProtocol"
                data-protocollo={id}
                onBlur={protocolSaving}
                placeholder="Aggiungi n° protocollo"
              />
            </FormGroup>
            <label htmlFor="areaProtocol" style={{ marginBottom: "-10px" }}>
              Aggiungi data protocollo
            </label>
            <FormGroup>
              <input
                className="form-control"
                type="date"
                id="areaProtocol"
                data-protocollo={id}
                onBlur={protocolSaving}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleProtocol}>
            Chiudi
          </Button>
          <Button color="primary" onClick={saveProtocol}>
            Salva modifiche
          </Button>
        </ModalFooter>
      </Modal>
      {/* pagament */}

      <Modal
        fade
        centered
        isOpen={openPayment}
        toggle={togglePayment}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={togglePayment} id="esempio14">
          PAGAMENTO , ID: {id} , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <label htmlFor="areaPayment"></label>
              <input
                className="form-control"
                id="areaPayment"
                data-protocollo={id}
                onBlur={paymentSaving}
                placeholder="Aggiungi importo pagamento (Es: 1452.56)"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="areaCasuale"></label>
              <input
                className="form-control"
                id="areaCasuale"
                data-protocollo={id}
                onBlur={paymentSaving}
                placeholder="Aggiungi causale"
              />
            </FormGroup>
            <FormGroup>
              <select
                style={{
                  width: "100%",
                  border: "none",
                  borderBottom: "solid 1px darkgrey",
                }}
                id="method"
                onChange={handleChangeMethod}
              >
                <option selected disabled>
                  Scegli tipologia di pagamento
                </option>
                <option value={defaultPaymentMethod[0].value}>
                  {defaultPaymentMethod[0].label}
                </option>
                <option value={defaultPaymentMethod[1].value}>
                  {defaultPaymentMethod[1].label}
                </option>
              </select>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePayment}>
            Chiudi
          </Button>
          <Button color="primary" onClick={savePayment}>
            Salva modifiche
          </Button>
        </ModalFooter>
      </Modal>
      {/* prenotazione appuntamento */}
      <Modal
        fade
        centered
        isOpen={open3}
        toggle={toggle3}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={toggle3} id="esempio14">
          DETTAGLI , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <tr>
                {ufficio.richiedente?.nome + " " + ufficio.richiedente?.cognome}
              </tr>
              <tr>{ufficio.richiedente?.telefono}</tr>
              <tr>{ufficio.richiedente?.email}</tr>
              <tr>
                {ufficio?.appuntamenti_disponibili?.value +
                  " alle " +
                  ufficio?.disponibilita?.value}
              </tr>
              <tr>{ufficio.ufficio?.value}</tr>
              <tr>{ufficio?.motivo}</tr>
              <tr>{ufficio?.dettagli}</tr>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle3}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* richiesta assistenza */}
      <Modal
        fade
        centered
        isOpen={open4}
        toggle={toggle4}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={toggle4} id="esempio14">
          DETTAGLI , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <tr>
                {data.richiedente?.nome + " " + data.richiedente?.cognome}
              </tr>
              <tr>{data.richiedente?.telefono}</tr>
              <tr>{data.richiedente?.email}</tr>
              <tr>{data.richiesta?.categoriaDiServizio}</tr>
              <tr>{data.richiesta?.servizio}</tr>
              <tr>{data.richiesta?.dettaglio}</tr>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle4}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* INFO */}
      <Modal
        fade
        centered
        isOpen={openInfo}
        toggle={toggleInfo}
        labelledBy="esempio14"
      >
        <ModalFooter>
          <FormGroup>
            <tr>
              {
                "Per modificare lo stato della pratica è necessario aggiungere prima il numero e la data di protocollo."
              }
            </tr>
          </FormGroup>
          <Button color="secondary" onClick={toggleInfo}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* INFO PAYMENT */}
      <Modal
        fade
        centered
        isOpen={openInfoPayAmount}
        toggle={toggleInfoPayAmount}
        labelledBy="esempio14"
      >
        <ModalFooter>
          <FormGroup>
            <tr>
              {
                "F-24 con importo uguale a 0, non è possibile aggiungere un pagamento."
              }
            </tr>
          </FormGroup>
          <Button color="secondary" onClick={toggleInfoPayAmount}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* INFO PROTOCOL */}
      <Modal
        fade
        centered
        isOpen={openInfoProtocol}
        toggle={toggleInfoProtocol}
        labelledBy="esempio14"
      >
        <ModalFooter>
          <FormGroup>
            <tr>
              {"Per aggiungere il n° protocollo della pratica è necessario ."}
            </tr>
          </FormGroup>
          <Button color="secondary" onClick={toggleInfoProtocol}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* INFO PAYMENT */}
      <Modal
        fade
        centered
        isOpen={openInfoPayment}
        toggle={toggleInfoPayment}
        labelledBy="esempio14"
      >
        <ModalFooter>
          <FormGroup>
            <tr>
              {
                "Per collegare un pagamento alla pratica è necessario che lo stato della pratica sia 'conclusa'."
              }
            </tr>
          </FormGroup>
          <Button color="secondary" onClick={toggleInfoPayment}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
      {/* segnalazione disservizio */}
      <Modal
        fade
        centered
        isOpen={open5}
        toggle={toggle5}
        labelledBy="esempio14"
      >
        <ModalHeader toggle={toggle5} id="esempio14">
          DETTAGLI , PRATICA N°: {practice_n}
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              {dataDisservizio.autore_della_segnalazione &&
                dataDisservizio.autore_della_segnalazione.length > 0 && (
                  <>
                    <tr>
                      <td>
                        {dataDisservizio.autore_della_segnalazione[0].anagrafica
                          ?.nome || ""}{" "}
                        {dataDisservizio.autore_della_segnalazione[0].anagrafica
                          ?.cognome || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {dataDisservizio.autore_della_segnalazione[0].contatti
                          ?.telefono || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {dataDisservizio.autore_della_segnalazione[0].contatti
                          ?.email || ""}
                      </td>
                    </tr>
                  </>
                )}

              <tr>{dataDisservizio.disservizio?.cerca_un_luogo}</tr>
              <tr>{dataDisservizio.disservizio?.tipo_di_disservizio}</tr>
              <tr>{dataDisservizio.disservizio?.titolo}</tr>
              <tr>{dataDisservizio.disservizio?.dettagli}</tr>
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            id="btn"
            color="primary"
            onClick={() => onImageDownload(id, practice_n)}
          >
            Scarica Immagine
          </Button>

          <Button color="secondary" onClick={toggle5}>
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </MainLayout>
  );
}
